<!---->
<mat-sidenav-container>
	<mat-sidenav mode="side" [opened]="true" position="end">
		<button mat-mini-fab
				title="Összecsukom"
				(click)="sidenav.toggle()"
				class="close-menu">
			<mat-icon>menu_open</mat-icon>
		</button>
		<ng-container *ngFor="let menuItem of sideMenuElements; let i = index">
			<button mat-mini-fab
				(click)="loadMenuComponent(i);"
				[class.active]="activeMenu === menuItem.title"
				[title]="menuItem.title">
				<mat-icon>{{ menuItem.icon }}</mat-icon>
			</button>
		</ng-container>
	</mat-sidenav>
	<mat-sidenav-content id="side-menu">
		<div class="sidenav-background" [style.background-image]="background"></div>
		<ng-template appMenu></ng-template>
	</mat-sidenav-content>
</mat-sidenav-container>