import {Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    constructor(private datePipe: DatePipe) { }


    transform(value: any, args?: any): any {
        if (value) {
            const now = new Date();
            const time = new Date(value);
            if (+now > +time) {
                const seconds = Math.floor((+now - +time) / 1000);
                if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                    return 'Éppen most';
            }
            const day = this.datePipe.transform(time, 'yyyy-MM-dd');
            const today = this.datePipe.transform(now, 'yyyy-MM-dd');
            if (day == today)
                return 'Ma, ' + this.datePipe.transform(time, 'H:mm');
            now.setHours(0);
            now.setMinutes(0);
            now.setSeconds(0, 0);
            time.setHours(0);
            time.setMinutes(0);
            time.setSeconds(0, 0); 
            if (+now - +time == 86400000)
                return 'Tegnap, ' + this.datePipe.transform(new Date(value), 'H:mm');
            if (+time - +now == 86400000)
                return 'Holnap, ' + this.datePipe.transform(new Date(value), 'H:mm');
            now.setDate(0);
            time.setDate(0);
            now.setMonth(0);
            time.setMonth(0);
            if (now.getFullYear() != time.getFullYear())
                return this.datePipe.transform(new Date(value), 'yyyy. MMMM d., H:mm');
            return this.datePipe.transform(new Date(value), 'MMMM d., H:mm');
        }
        return value;
    }

}