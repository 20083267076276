
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filenameslugify'})
export class FilenameSlugifyPipe implements PipeTransform {
  transform(input: string): string {
    const fileNameParts = input.split('.');
    const extension = fileNameParts.pop();
    let fileName = fileNameParts.join('-');

    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/-,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz______'
    const p = new RegExp(a.split('').join('|'), 'g')
  
    fileName =  fileName.toString().toLowerCase()
      .replace(/\s+/g, '_') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '_and_') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\_\-_+/g, '_') // Replace multiple - with single -
      .replace(/^_+/, '') // Trim - from start of text
      .replace(/_+$/, ''); // Trim - from end of text
    return `${fileName}.${extension}`;
  }
}