<div class="content-wrapper">
	<header #siteHeader class="fx-layout-row space-between">
		<div #header id="logo" [routerLink]="default_location">
			<img [srcset]="srcset" [src]="logosvg" alt="Koncertmester.hu" class="site-logo">
			Koncertmester
			<mat-icon *ngIf="networkStatus === false"
				class="material-icons-outlined"
				title="Nincs internetkapcsolat!">signal_wifi_off</mat-icon>
		</div>
		<div class="headline fx-layout-column fx-align-center fx-hide-lt-md">
			<h2>Klasszikus zenei hangversenynaptár - határokon innen és túl</h2>
		</div>
		<div class="menu-icons">
			<ng-container *ngIf="user">
				<button mat-button
					class="notifications"
					title="Értesítések"
					[matMenuTriggerFor]="notificationMenu"
					(menuOpened)="isOpened($event)"
            		(menuClosed)="isClosed($event)">
					<mat-icon [matBadge]="num_of_notifications"
						[matBadgeHidden]="num_of_notifications == 0"
						matBadgeColor="accent"
						class="material-icons-outlined">notifications</mat-icon>
				</button>
				<mat-menu #notificationMenu="matMenu" xPosition="after" yPosition="below">
					<ng-template matMenuContent>
						<app-user-notifications [open]="menu_open"></app-user-notifications>
					</ng-template>
				</mat-menu>
			</ng-container>
			<button mat-button
				class="menu-button"
				title="Menü"
				(click)="sidenav.toggle()">
				<mat-icon>menu</mat-icon>
			</button>
		</div>
	</header>
	<span #sidenavContainer>
		<mat-sidenav-container>
			<mat-sidenav-content>
				<div class="shadow"></div>
				<router-outlet></router-outlet>
			</mat-sidenav-content>
			<mat-sidenav #sidenav mode="over" [opened]="false" position="end">
				<app-sidenav [sidenav]=sidenav></app-sidenav>
			</mat-sidenav>
		</mat-sidenav-container>
	</span>
</div>