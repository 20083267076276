import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { City } from '../shared/models/city.model';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { Country } from '../shared/models/country.model';
import { MatSidenav } from '@angular/material/sidenav';
import { DOCUMENT } from '@angular/common';
import { UserService } from '../user/user.service';

interface Favorite {
  name: string,
  slug: string,
  category: string,
  last_concert: Date
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
homePageOpen = new BehaviorSubject<boolean>(false);
currentFavorite = new BehaviorSubject<Favorite>(undefined);
currentCity = new BehaviorSubject<City>(undefined);
currentRegion = new BehaviorSubject<City>(undefined);
currentCountry = new BehaviorSubject<Country>(undefined);
countryList = new BehaviorSubject<Country[]>(undefined);
lastScroll = new BehaviorSubject<number>(0);
startDate = new BehaviorSubject<string>(undefined);
selectedMonth = new BehaviorSubject<string>(undefined);
highlightedCities = new BehaviorSubject<City[]>(undefined);
highlightedRegions = new BehaviorSubject<City[]>(undefined);
netStatus = new BehaviorSubject<boolean>(true);

sideNav = new BehaviorSubject<MatSidenav>(undefined);

time_filter = {
  'morning': true,
  'afternoon': true,
  'evening': true
};

timeSettings = new BehaviorSubject<Object>(this.time_filter);


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private http: HttpClient,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private dom
    ) {
      this.meta.addTag({name: 'keywords', property: 'keywords', content: 'koncertnaptár, hangversenynaptár, komolyzene, koncert, hangverseny, klasszikus, kortárs, szimfonikus, kamarazene, opera, kórus, balett'});
   }

  toggleSidenav() {
    const screenWidth =  window.innerWidth;
    if (screenWidth < 600 || screenWidth >= 1280)
      this.sideNav.getValue().toggle();
  }

  
  setTitle(title: string, url: string = '') {
    let numstring: string = '';
    if (this.authService.checkBrowser()) {
      const num_of_notifications = this.userService.notificationCount.getValue();
      if (num_of_notifications)
        numstring = `(${num_of_notifications}) `;
    }
    this.title.setTitle(numstring + title  + ' | Koncertmester hangversenynaptár');
    const fullURL = `https://koncertmester.hu/${url}`;
    this.meta.removeTag("property='og:url'");
    this.meta.addTag({property: 'og:url', content: fullURL});
    this.updateCanonicalUrl(fullURL);
  }

  setNotificationcountInTitle(num_of_notifications: number) {
    const original_title = this.title.getTitle();
    const num_match = original_title.substring(0, 7).match(/\(\d+\)\s/gm);
    let page_title: string = original_title;
    if (num_match != null) {
      const title_parts = original_title.split(num_match[0]);
      page_title = title_parts[1];
    }       
    let numstring: string = '';
    if (num_of_notifications)
      numstring = `(${num_of_notifications}) `;
    this.title.setTitle(numstring + page_title);
  }

  setDescription(description: string) {
    this.meta.removeTag("name='description'");
    this.meta.addTag({name: 'description', property: 'description', content: description});
  }

  setFB(title: string, description: string, image: string = undefined) {
    this.meta.removeTag("property='og:title'");
    this.meta.removeTag("property='og:description'");
    this.meta.removeTag("property='og:image'");
    this.meta.addTag({property: 'og:title', content: title});
    this.meta.addTag({property: 'og:description', content: description});
    if (image)
      this.meta.addTag({property: 'og:image', content: `${environment.img}${image}`});
  }

  lookupCity(slug: string) {
    this.getCity(slug)
				.subscribe(city => this.updateCity(city));

  }

  updateCity(city: City) {
    this.currentCity.next(city);
    if (this.authService.checkBrowser()){
      localStorage.setItem('City', JSON.stringify(city));
      localStorage.removeItem('Region');
    }
    this.currentRegion.next(undefined);
    this.updateCountry(city.country);
  }

  updateCountry(country: Country) {
    const current = this.currentCountry.getValue();
    if (country && (!current || country.slug != current.slug)){
      this.currentCountry.next(country);
    }
  }

  lookupRegion(slug: string) {
    this.getRegion(slug)
    .subscribe(region => this.updateRegion(region));
  }


  updateRegion(region: City) {
    this.currentRegion.next(region);
        if (this.authService.checkBrowser()){
          localStorage.setItem('Region', JSON.stringify(region));
          localStorage.removeItem('City');
        }
        this.currentCity.next(undefined);
        this.updateCountry(region.countries[0]);
  }

  updateFavorite(slug: string) {
    let category: string;
    let name: string;
    if (slug == 'telepulesek'){
      category = 'favorite-cities';
      name = 'Kedvenc településeim';
    }
    else if (slug == 'regiok') {
      category = 'favorite-regions';
      name = 'Kedvenc régióim';
    }
    else if (slug == 'helyszinek') {
      category = 'favorite-venues';
      name = 'Kedvenc helyszíneim';
    }
    else if (slug == 'osszes-terulet'){ 
      category = 'favorite-areas';
      name = 'Kedvenc területeim';
    }
    else if (slug == 'eloadok') {
      category = 'favorite-performers';
      name = 'Kedvenc előadóim';
    }
   this.getFavorite(slug)
				.subscribe(result => {
            const favorite: Favorite = {
              name: name,
              slug: slug,
              category: category,
              last_concert: result.last_concert
            }
            this.currentFavorite.next(favorite);
					});
  }

  updateCountries(){
    this.listCountries().subscribe(
      countries => this.countryList.next(countries)
    );
  }

  updateHCities(country: Country){
    this.listHighlightedCities(country).subscribe(
      hcities => {
        if(hcities)
          this.highlightedCities.next(hcities);
      }
    );
  }

  updateHRegions(country: Country){
    this.listRegions(country).subscribe(
     regions => {
        if(regions)
          this.highlightedRegions.next(regions);
      }
    );
  }

  getFavorite(cat: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/last-favorite-concert/${cat}/`, {'headers':this.authService.createHeader()});
  }

  restoreLastParameters(url: string) {
    const isBrowser = this.authService.checkBrowser();
    const lastcity = (isBrowser) ? localStorage.getItem('City') : undefined;
    const lastregion = (isBrowser) ? localStorage.getItem('Region') : undefined;
    const start = (isBrowser) ? localStorage.getItem('Start') : undefined;
    const month = (isBrowser) ? localStorage.getItem('Month') : undefined;
    const time = (isBrowser) ? localStorage.getItem('Time') : undefined;

    if(!this.currentCity.getValue() && !this.currentRegion.getValue()){
      if(lastcity) 
        this.updateCity(JSON.parse(lastcity));
      else if (lastregion)
        this.updateRegion(JSON.parse(lastregion));
      else if (!url.startsWith('/2') && !url.startsWith('/helyszin/') && url.split('/').length > 2 || url == '/404')
        this.lookupRegion('magyarorszag');
    }

    if(start)
      this.startDate.next(start);

    if(month)
      this.selectedMonth.next(month);

    if(time)
      this.timeSettings.next(JSON.parse(time));
  }

  getCity(slug: string): Observable<City> {
    return this.http.get<City>(`${environment.api}/citydata/${slug}/`);
  }

  getRegion(slug: string): Observable<City> {
    return this.http.get<City>(`${environment.api}/regiondata/${slug}/`);
  }

  listCityHints(typed: string): Observable<City[]> {
    return this.http.get<City[]>(`${environment.api}/citysearch/${typed}/`);
    }

  listHighlightedCities(country: Country): Observable<City[]> {
      return this.http.get<City[]>(`${environment.api}/country/${country.id}/highlightedcities/`);
      }
  
  listRegions(country: Country): Observable<City[]> {

      return this.http.get<City[]>(`${environment.api}/country/${country.id}/regions/`);
      }

  listCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${environment.api}/countries/`);
    }

  listAllCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${environment.api}/all-countries/`);
    }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }
}
