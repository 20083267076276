
<div mat-menu-item class="spinner-wrapper" *ngIf="loading">
	<mat-spinner diameter="26"></mat-spinner>
</div>
<div *ngIf="!loading && (!notifications || !notifications.length)" mat-menu-item>
    <p>Nincs új értesítésed.</p>
</div>
<ng-container *ngFor="let notification of notifications; let i = index">
    <div *ngIf="!loading" mat-menu-item [class.new]="!notification.seen" [class.not-clicked]="!notification.clicked" (click)="notificationClick(notification)">
        <h2>
            {{ notification.title }}
        </h2>
        <p>
            {{ notification.content }}
        </p>
        <p class="time">
            {{ notification.time | dateAgo }}
        </p>
    </div>
    <mat-divider *ngIf="i + 1 < notifications.length"></mat-divider>
</ng-container>
