import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Notification } from 'src/app/shared/models/notification.model';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() open: boolean;
  loading: boolean = false;
  empty: boolean = false;
  notifications: Notification[] = [];

  private subscription: Subscription = new Subscription();

  constructor(private userService: UserService, private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    let openchange = changes['open'];
    if (openchange && !openchange.firstChange && this.open)
      this.seenNotifications();
  }

  ngOnInit(): void {
    this.subscription.add(this.userService.notificationLoading.subscribe(
      loading => this.loading = loading
    ));

    this.subscription.add(this.userService.notificationList.subscribe(
      notifications => {
        this.notifications = notifications;
        if (this.open)
          this.seenNotifications();
      }
    ));
    this.userService.notificationCount.next(0);
  }

  seenNotifications() {
    if(this.notifications){
      const not_seen: Notification[] = this.notifications.filter(obj => {
        return obj.seen === false
      });
      const not_seen_ids: number[] = not_seen.map(a => a.id);
      if (not_seen_ids.length)
        this.subscription.add(this.userService.markSeenNotifications(not_seen_ids).subscribe());
        not_seen.forEach((notification: Notification, index) => notification.seen = true);
    }
  }

  isValidUrl(url) {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  }

  isExternalURL(url) {
    if (this.isValidUrl(url))
      return new URL(url).origin !== location.origin;
    return false;
  }

  notificationClick(notification: Notification) {
    this.subscription.add(this.userService.updateNotification(notification.id).subscribe());
    notification.clicked = true;
    notification.seen = true;
    if(!this.isExternalURL(notification.target))
      this.router.navigateByUrl(notification.target);
    else
      window.open(notification.target);
  }

  ngOnDestroy(): void {
    this.open = false;
    this.seenNotifications();
    this.subscription.unsubscribe();
  }

}
