import { Component, OnInit, ViewChild, Input, OnDestroy, Type, NgModuleRef, Injector, createNgModule } from '@angular/core';
import { NavigationService } from '../navigation.service';
import { MenuDirective } from './menu.directive';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Profile } from 'src/app/shared/models/profile.model';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

interface MenuComponent {
  loadModule: any,
  icon: string,
  title: string,
  background: string
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  sideMenuElements: MenuComponent[] = [
    {
      loadModule: () => {
        import('../location/location.module').then(m => this.openLazyLoadedComponent(m));
      },
      icon: 'place',
      title: 'Földrajzi terület',
      background: 'sidenav_location.webp'
    },
    {
      loadModule: () => {
        import('../date/date.module').then(m => this.openLazyLoadedComponent(m));
      },
      icon: 'today',
      title: 'Időszak',
      background: 'sidenav_date.webp'
    },
    {
      loadModule: () => {
        import('../time/time.module').then(m => this.openLazyLoadedComponent(m));
      },
      icon: 'schedule',
      title: 'Napszak',
      background: 'sidenav_time.webp'
    },
    {
      loadModule: () => {
        import('../login/login.module').then(m => this.openLazyLoadedComponent(m));
      },
      icon: 'person',
      title: 'Saját fiókom',
      background: 'sidenav_login.webp'
    },
    {
      loadModule: () => {
        import('../informations/informations.module').then(m => this.openLazyLoadedComponent(m));
      },
      icon: 'info_outline',
      title: 'Információk',
      background: 'sidenav_info.webp'
    }
  ];
  favorites = {
    loadModule: () => {
      import('../favorites/favorites.module').then(m => this.openLazyLoadedComponent(m));
    },
    icon: 'favorite_border',
    title: 'Kedvenceim',
    background: 'sidenav_favorites.webp'
  }

  background: string;

  @Input() sidenav: MatSidenav;

  @ViewChild(MenuDirective, {static: true}) appMenu: MenuDirective;
  activeMenu: string;

  private subscription: Subscription = new Subscription();

  constructor(
    public navigationService: NavigationService,
    private authService: AuthService,
    private _injector: Injector
  ) { }

  ngOnInit() {
    this.subscription.add(this.authService.currentUser.subscribe(
      user => {
      if (this.hasFavorites(user) && !this.sideMenuElements.includes(this.favorites))
        this.sideMenuElements.splice(3, 0, this.favorites);
      else if (!this.hasFavorites(user))
        this.sideMenuElements = this.sideMenuElements.filter(item => {
          return item.title !== this.favorites.title
        });
      }
    ));
    this.loadMenuComponent(0);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  hasFavorites(user: Profile){
    if(user && (
      user.favorite_cities.length ||
      user.favorite_concerts.length ||
      user.favorite_performers.length ||
      user.favorite_regions.length ||
      user.favorite_venues.length ||
      user.favorite_concerts.filter(concert => {
        return new Date(concert.event_time).getTime() > new Date().getTime()
     }).length
    ))
    return true;
    return false;
  }

  loadMenuComponent(menuId: number){
  if(this.activeMenu != this.sideMenuElements[menuId].title){
    this.background = `url(${environment.styling}${this.sideMenuElements[menuId].background})`;
    const viewContainerRef = this.appMenu.viewContainerRef;
    viewContainerRef.clear();
    this.sideMenuElements[menuId].loadModule();
    this.activeMenu = this.sideMenuElements[menuId].title;
    }
  }

  openLazyLoadedComponent<T>(importedFile: T): void {
    const module = (<any>importedFile)[Object.keys(importedFile)[0]];

    const moduleRef: NgModuleRef<T> = createNgModule(module, this._injector);
    const componentToOpen = module.components.dynamicComponent;
    this.appMenu.viewContainerRef.createComponent(componentToOpen, { ngModuleRef: moduleRef });
  }
}

