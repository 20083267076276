import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultRouteGuardService } from './default-route-guard.service';
import { E404Component } from './shared/e404/e404.component';
import { VenueRedirectRouteGuardService } from './venue-redirect-route-guard.service';
const routes: Routes = [
    {
      path: '',
      canActivate: [DefaultRouteGuardService]
    },
    {
      path: '404',
      component: E404Component
    },
    {
      path: 'profil',
      loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },
    {
      path: 'informaciok',
      loadChildren: () => import('./info/info.module').then(m => m.InfoModule)
    },
    {
      path: 'kedvenceim/koncertek',
      loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
    },
    {
      path: 'kedvenceim/:slug',
      loadChildren: () => import('./concert-list/concert-list.module').then(m => m.ConcertListModule)
    },
    {
      path: 'regio/:slug',
      loadChildren: () => import('./concert-list/concert-list.module').then(m => m.ConcertListModule)
    },
    {
      path: 'eloado/:performer/:subscription',
      loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
    },
    {
      path: 'eloado/:performer',
      loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
    },
    {
      path: 'helyszin/:city/:slug',
      loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
    },
    {
      path: 'helyszin/:slug',
      canActivate: [VenueRedirectRouteGuardService]
    },
    {
      path: ':year/:month/:day/:slug',
      loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
    },
    {
      path: ':slug',
      loadChildren: () => import('./concert-list/concert-list.module').then(m => m.ConcertListModule)
    },
    {
      path: '**',
      component: E404Component
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation : 'enabledBlocking',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
