import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Profile } from '../shared/models/profile.model';
import { tap } from 'rxjs/operators';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { isPlatformBrowser } from '@angular/common';
import { SocialUser } from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser = new BehaviorSubject<Profile>(undefined);
  defaultLocation = new BehaviorSubject<string>(undefined);
  editMode = new BehaviorSubject<boolean>(false);
  adOpen = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: any
    ) { }

  checkBrowser() {
    if (environment.ssr) {
      if (isPlatformBrowser(this.platformId))
        return true;
      return false;
    }
    return true;
  }
  
  socialSignIn(authToken: string, provider: string): Observable<any> {
    if (!provider && this.checkBrowser())
      provider = localStorage.getItem('Provider');
    return this.http.post<string>(`${environment.users}/rest-auth/${provider}/`, { access_token: authToken })
    .pipe(
      tap(response => {
        this.storeToken(response.key);
        return this.setCurrentUser(response.key);
      })
    );
  }

  googleSignIn(user: SocialUser): Observable<any> {
    const body = JSON.stringify(user);
    const headers = {'content-type': 'application/json'};
    return this.http.post<any>(`${environment.users}/rest-auth/google/`, body, {'headers':headers});
  }

  emailSignIn(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${environment.users}/rest-auth/login/`, { email: email, password: password })
      .pipe(
        tap(response => {
          this.storeToken(response.key);
          return this.setCurrentUser(response.key);
        })
      );
  }

  emailLogout(): Observable<any> {
    return this.http.post<string>(`${environment.users}/rest-auth/logout/`, {})
      .pipe(
        tap( () => {
          this.currentUser.next(undefined);
          if (this.checkBrowser())
            this.clearLocalStorage();
        })
    );
  }

  storeToken(token: string) {
    if (this.checkBrowser()){
      localStorage.setItem('Token', token);
      this.setNewExpiration(new Date());
    }
  }

  checkExpiration() {
    let date = new Date();
    const day = date.getDate()
    if (!localStorage.getItem('Expiration') || day > new Date(localStorage.getItem('Expiration')).getDate() + 1)
      this.setNewExpiration(date);
  }

  setNewExpiration(date: Date) {
    date.setDate(date.getDate() + 30);
    localStorage.setItem('Expiration', (date.getTime()/1000).toString());
  }

  isTokenValid() {
    if (this.checkBrowser()){
      if(localStorage.getItem('Expiration')) {
        const expiration = Number(localStorage.getItem('Expiration'));
        return expiration >= new Date().getTime()/1000;
      }
    return false;  
    }
  }

  getToken(): string {
    if (this.isTokenValid() && this.checkBrowser()){
      this.checkExpiration();
      return localStorage.getItem('Token');
    }
    else {
      this.clearLocalStorage();
      this.defaultLocation.next('/regio/magyarorszag');
      return undefined;
    }
  }

  clearLocalStorage() {
    if (this.checkBrowser()) {
      localStorage.removeItem('Token');
      localStorage.removeItem('Expiration');
      sessionStorage.removeItem('edit_mode');
    }
  }

  loadUserProfile(token: string): Observable<Profile> {
    const uuid: string = (this.checkBrowser()) ? localStorage.getItem('UUID') : undefined;
    return this.http.get<Profile>(`${environment.users}/user-profile/`, {'headers':this.createHeader(uuid)});
}

  setCurrentUser(token: string){
    this.loadUserProfile(token).subscribe({
      next:user => {
        this.currentUser.next(user);
        if (user && this.checkBrowser())
          localStorage.setItem('UUID', user.uuid);
      },
      error:error => this.clearLocalStorage()
    });
  }

  emailSignUp(user: Object): Observable<any> {
    const body = JSON.stringify(user);
    const headers = {'content-type': 'application/json'};
    return this.http.post<any>(`${environment.users}/rest-auth/registration/`, body, {'headers':headers});
  }

  requestPasswReset(email: string): Observable<any> {
    const formData = new FormData();
    formData.append('email', email);
    return this.http.post<any>(`${environment.users}/password-reset/`, formData);
  }

  sendPasswReset(password1: string, password2: string, key: string): Observable<any> {
    const formData = new FormData();
    formData.append('password1', password1);
    formData.append('password2', password2);
    return this.http.post<any>(`${environment.users}/password-reset/key/${key}/`, formData);
  }

  resendVerificationEmail(email: string): Observable<any> {
    const formData = new FormData();
    formData.append('email', email);
    return this.http.post<any>(`${environment.users}/resend-verification-email/`, formData);
  }

  changePassword(oldpassword: string, password1: string, password2: string,): Observable<any> {
    const formData = new FormData();
    formData.append('oldpassword', oldpassword);
    formData.append('new_password1', password1);
    formData.append('new_password2', password2);
    return this.http.post<any>(`${environment.users}/change-password/`, formData, {'headers':this.createHeader()});
  }

  createHeader(uuid = undefined){
    if (uuid)
      return {
        'Authorization': `Token ${this.getToken()}`,
        'User-UUID': uuid
      };
    return {'Authorization': `Token ${this.getToken()}`};
  }

  uuidHeader(uuid) {
    if (uuid)
      return {'User-UUID': uuid};
    return {};
  }
  
  getInternalUser() {
    return this.cookieService.get('internal_user');
  }

  setInternalUser() {

    let d:Date = new Date();
    d.setTime(d.getTime() + 31104000 * 1000);
    const options: CookieOptions = {
      path: '/',
      expires: d
    }

    this.cookieService.put('internal_user', 'internal', options);
  }
}
