import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { E404Component } from './e404/e404.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { MatCardModule } from '@angular/material/card';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CookieSettingsComponent } from './cookie-settings/cookie-settings.component';
import { FilenameSlugifyPipe } from '../pipes/filename-slugify.pipe';
import { InfoMenuComponent } from './info-menu/info-menu.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ChipComponent } from './chip/chip.component';
import { MatChipsModule } from '@angular/material/chips';
import { E504Component } from './e504/e504.component';
import { SpinnerComponent } from './spinner/spinner.component';



@NgModule({
  declarations: [
    UserNotificationsComponent,
    ToolbarComponent,
    DateAgoPipe,
    FilenameSlugifyPipe,
    E404Component,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    CookieSettingsComponent,
    InfoMenuComponent,
    AvatarComponent,
    ChipComponent,
    E504Component,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatChipsModule
  ],
  providers: [DateAgoPipe, FilenameSlugifyPipe],
  exports:  [
    UserNotificationsComponent,
    ToolbarComponent,
    DateAgoPipe,
    FilenameSlugifyPipe,
    E404Component,
    E504Component,
    SpinnerComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    InfoMenuComponent,
    AvatarComponent,
    ChipComponent
  ]
})
export class SharedModule { }
