const base_url = 'https://backend.koncertmester.hu';
//const base_url = 'http://api.example.com:8080';

export const environment = {
  production: true,
  ssr: true,
  api: `${base_url}/api/v1`,
  users: `${base_url}/users/v1`,
  manager: `${base_url}/manager/v1`,
  img: base_url,
  logo: base_url,
  styling:  '/assets/',
  showGoogleAds: true,
  adtest: undefined
};
