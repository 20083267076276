import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { NavigationService } from './navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})

export class DefaultRouteGuardService implements CanActivate {


  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private router: Router
    ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.id === 2){
        this.navigationService.homePageOpen.next(true);
      }
    });
  }
  

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.getDefaultLocation();
      return false;
	}

  getDefaultLocation(){
    if (!this.authService.getToken())
      this.router.navigate(['/regio/magyarorszag']);
    else {
      this.authService.defaultLocation.subscribe({
        next:location => {
          if(location)
          this.router.navigate([location]);
        }
      });
    }
  }
}
