import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VenueRedirectRouteGuardService {
  environment

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const slug = route.paramMap.get('slug');
    this.doRedirect(slug);
    return false;
  }

  doRedirect(slug: string) {
    this.getRoute(slug).subscribe({
      next:location => {
        if(location)
        this.router.navigate([`/helyszin/${location.city}/${location.slug}`]);
      },
      error:error => this.router.navigate(['/404'])
    })
  }

  getRoute(slug: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/redirect-venue/${slug}/`)
  }

}
