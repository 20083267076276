import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { AuthService } from './auth/auth.service';
import { NavigationService } from './navigation/navigation.service';
import { UserService } from './user/user.service';
import { v4 as uuidv4 } from 'uuid';
import { MatSidenav } from '@angular/material/sidenav';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Profile } from './shared/models/profile.model';
import { Subscription, fromEvent, merge, of } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
//import { CookieSettingsComponent } from './shared/cookie-settings/cookie-settings.component';

//declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Koncertmester';
  screenWidth: number;
  authToken: string;
  uuid: string;
  permanentlyopen: boolean = false;
  sidenavmode: string = 'over';

  user: Profile;
  num_of_notifications: number;
  default_location: string = '/regio/magyarorszag';

  isBrowser: boolean = false;

  @ViewChild('siteHeader') siteHeader: ElementRef;
  @ViewChild('sidenavContainer') sidenavContainer: ElementRef;

  @ViewChild('sidenav',  { static: true }) sidenav: MatSidenav;
  @Inject(DOCUMENT) private document: Document;

  logosvg: string = `${environment.styling}logo.svg`;
  logopng: string = `${environment.styling}logo.png`;
  srcset: string = '';

  notification_interval;

  consentChecked: boolean = false;

  consent: boolean = false;
  ga_consent: boolean = false;

  homePage: boolean = false;

  lastUrl: string;

  menu_open: boolean = false;

  private subscription: Subscription = new Subscription();

  networkStatus: boolean;
  networkStatus$: Subscription = Subscription.EMPTY;

  snackbarConfig: MatSnackBarConfig = {
    duration: 4000,
    horizontalPosition: 'start',
    verticalPosition: 'bottom',
  };


  constructor(
    private dateAdapter: DateAdapter<Date>,
    public authService: AuthService,
    private userService: UserService,
    public navigationService: NavigationService,
    private snackBar: MatSnackBar,
    private router: Router
    ) {
      this.isBrowser = this.authService.checkBrowser();
      this.srcset = `${this.logopng} 1x, ${this.logosvg} 2x`;
      this.screenWidth = window.innerWidth;
      this.checkWindowSize();
      this.dateAdapter.getFirstDayOfWeek = () => { return 1; };

      this.authToken = this.authService.getToken();
      if (this.authToken)
        this.authService.setCurrentUser(this.authToken);
      else {
        this.authService.currentUser.next(undefined);
        if (this.isBrowser){
          const uuid = localStorage.getItem('UUID');
          if (!uuid || uuid == null)
            {
              if(this.screenWidth)
                this.uuid = uuidv4();
              else
                this.uuid = null;
              localStorage.setItem('UUID', this.uuid);
            }
          }
      }

      this.subscription.add(router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if((event.id === 1 || (event.id === 2 && this.navigationService.homePageOpen.getValue() == true)) && !event.url.startsWith('/assets/')) {
            this.navigationService.restoreLastParameters(event.url);
            this.navigationService.homePageOpen.next(false);
          }
        }
      }));
    }

  ngOnInit(): void {
      if (this.isBrowser)
        this.checkNetworkStatus();
    
      this.subscription.add(this.userService.notificationCount.subscribe(
        notificationcount => {
          this.num_of_notifications = notificationcount;
          this.navigationService.setNotificationcountInTitle(notificationcount);
        }
      ));

      this.subscription.add(this.authService.currentUser.subscribe({
        next:user => {
          this.user = user;
          if (user){
            if(this.notification_interval)
              clearInterval(this.notification_interval);
            this.checkNotificationCount();
            this.notification_interval = setInterval(() => {
                  this.checkNotificationCount();   
            }, 120000);
            if (this.isEditMode(user))
              this.authService.editMode.next(true);
            if (user.default_location_slug) {
              this.authService.defaultLocation.next(user.default_location_slug);
              this.default_location = user.default_location_slug;
            }
            else {
              this.authService.defaultLocation.next('/regio/magyarorszag');
              this.default_location = '/regio/magyarorszag';
            }
          } else {
            this.authService.defaultLocation.next('/regio/magyarorszag');
            if(this.notification_interval)
              clearInterval(this.notification_interval);
            this.userService.notificationList.next(undefined);
            this.userService.notificationCount.next(0);
            }
/*
            if(user && this.ga_consent)
              this.authService.setConsentCookie(true);
            */
        }
    })
    );   

    if(this.sidenav)
      this.navigationService.sideNav.next(this.sidenav);
  }
/*
  ngAfterViewInit(): void {
    if (this.isBrowser)
      gtag('js', new Date());
  }
*/
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.networkStatus$.unsubscribe();
    if(this.notification_interval)
      clearInterval(this.notification_interval);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
    this.checkWindowSize();
  }

  checkNotificationCount() {
    this.userService.getNotificationCount().subscribe(
      notificationCount => this.changeNotificationCount(notificationCount)
      );
  }

  changeNotificationCount(notificationCount: number) {
    if (typeof this.userService.notificationList.getValue() == 'undefined' || notificationCount > this.userService.notificationCount.getValue()){
      this.userService.notificationLoading.next(true);
      this.userService.notificationCount.next(notificationCount);
      this.userService.listNotifications().subscribe(
        notifications => {
          this.userService.notificationList.next(notifications);
          this.userService.notificationLoading.next(false);
        }
      );
    }
  }

  checkWindowSize(){
    if((this.screenWidth > 599) && (this.screenWidth < 1280)){
      this.permanentlyopen = true;
      this.sidenavmode = 'side';
    }
    else {
      this.permanentlyopen = false;
      this.sidenavmode = 'over';
    }
  }
/*
  initTag() {
    gtag('js', new Date());
    if (this.lastUrl)
      gtag('config', 'G-CC152J20M5',
        {
          page_path: this.lastUrl
        }
      );
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          if (this.isBrowser){
            gtag('config', 'G-CC152J20M5',
                  {
                      page_path: event.urlAfterRedirects
                  }
              );
          }
          else
            this.lastUrl = event.urlAfterRedirects;
        });
  }
*/
  isEditMode(user) {
    return this.isBrowser && 
    (user.performers.length || user.user.is_superuser) && 
    sessionStorage.getItem('edit_mode');
  }

  isOpened(evt:any){
    this.menu_open = true;
    }

  isClosed(evt:any){
    this.menu_open = false;
    }

    checkNetworkStatus() {
      this.networkStatus = navigator.onLine;
      this.networkStatus$ = merge(
        of(null),
        fromEvent(window, 'online'),
        fromEvent(window, 'offline')
      )
        .pipe(map(() => navigator.onLine))
        .subscribe(status => {
          this.navigationService.netStatus.next(status);
          this.showNetworkStatus(status);
        });
    }

    showNetworkStatus(status: boolean) {
      //console.log('status', status);
      if (this.networkStatus === false && status) {
        this.snackbarConfig.panelClass = ['green'];
        this.snackBar.open('Az internetkapcsolat helyreállt.', '', this.snackbarConfig);
      }
      else if (!status) {
        this.snackbarConfig.panelClass = ['red'];
        this.snackBar.open('Nincs internetkapcsolat!', '', this.snackbarConfig);
      }
      this.networkStatus = status;   
    }
}
