import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-e404',
  templateUrl: './e404.component.html',
  styleUrls: ['./e404.component.scss']
})
export class E404Component implements OnInit {
  @Input() category: string;
  page_category: string = "oldal";

  constructor() { }

  ngOnInit(): void {
    if (this.category) {
      if (this.category == 'city' || this.category == 'region')
        this.page_category = 'terület';
      else
        this.page_category = this.category;
    }
  }

}
